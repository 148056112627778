const DocMenuConfig = [
  // {
  //   heading: "Developer",
  //   route: "/debug",
  //   pages: [
  //     {
  //       heading: "Debug",
  //       route: "/debug",
  //       svgIcon: "media/icons/duotune/general/gen022.svg",
  //       fontIcon: "bi-archive",
  //     },
  //   ],
  // },
  {
    heading: "Hồ sơ",
    route: "/student",
    pages: [
      {
        heading: "Tạo tài khoản",
        route: "/account",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin", "teacher", "saler", "employee"],
      },
      {
        heading: "Giảng viên",
        route: "/teacher",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin"],
      },
      {
        heading: "Học viên",
        route: "/student",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin", "teacher", "saler", "employee"],
      },
      {
        heading: "Khách",
        route: "/guest",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin", "teacher", "saler", "employee"],
      },
      {
        heading: "Nhân viên",
        route: "/employee",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin", "teacher"],
      },
    ],
  },
  {
    heading: "Đào tạo",
    route: "/edu",
    pages: [
      {
        heading: "Chương trình học",
        route: "/course",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin"],
      },
      {
        heading: "Danh mục",
        route: "/course-cate",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin"],
      },
      {
        heading: "Lớp học",
        route: "/class",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin", "teacher", "saler", "employee"],
      },
      {
        heading: "Khóa học video",
        route: "/class-video",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin", "teacher", "saler", "employee"],
      },
    ],
  },
  {
    heading: "Thiền phòng",
    route: "/zen",
    pages: [
      {
        heading: "Thiền phòng tĩnh",
        route: "/static-room",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin", "teacher", "saler", "employee"],
      },
      {
        heading: "Sự kiện trực tuyến",
        route: "/event_edu",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin", "teacher", "saler", "employee"],
      },
    ],
  },
  {
    heading: "Marketing",
    route: "/news",
    pages: [
      {
        heading: "Tin tức",
        route: "/news",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin", "teacher", "saler", "employee", "marketer"],
      },
      {
        heading: "Danh mục tin tức",
        route: "/news-cate",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin", "teacher", "saler", "employee", "marketer"],
      },
    ],
  },
  {
    heading: "Notification",
    route: "/notification",
    pages: [
      {
        heading: "Thông báo",
        route: "/notification",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin", "teacher", "saler", "employee", "marketer"],
      },
    ],
  },
  {
    heading: "Hệ thống",
    route: "/system",
    pages: [
      {
        heading: "Bảng tin",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin", "teacher", "saler", "employee"],
      },
      {
        heading: "Báo cáo nâng cao",
        route: "/advance_report",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin"],
      },
      {
        heading: "Siri béo",
        route: "/siri",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin"],
      },
      {
        heading: "Chi nhánh",
        route: "/branch",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin"],
      },
      {
        heading: "Thương hiệu",
        route: "/brand",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin"],
      },
      {
        heading: "Danh ngôn",
        route: "/quote",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin"],
      },
      {
        heading: "SMS listening",
        route: "/sms",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        roles: ["admin"],
      },
    ],
  },
];

export default DocMenuConfig;
